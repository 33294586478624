import { useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { useLazyQuery } from '@apollo/client';
import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/20/solid';
import { GET_USER_BY_MEMBER_ID_QUERY } from '../../utils/graphql/queries';
import { validationSchema } from './validations/MemberIdValidation';

export default function MemberIdForm({ onSuccess }) {
    const initialValues = {
        memberId: '',
    };

    const [message, setMessage] = useState('');
    const [isAvailable, setIsAvailable] = useState(null);
    const [getUserByMemberId, { loading, error, data }] = useLazyQuery(GET_USER_BY_MEMBER_ID_QUERY, {
        onCompleted: (data) => {
            const { values } = formikRef.current;
            if (data.getUserByMemberId === null) {
                setMessage(`Member ID ${values.memberId} is available.`);
                setIsAvailable(true);
                onSuccess(true, values.memberId); // Notify parent component of success
            } else {
                setMessage(`Member ID ${values.memberId} is unavailable. Try again.`);
                setIsAvailable(false);
                onSuccess(false, values.memberId); // Notify parent component of failure
            }
        },
        onError: (error) => {
            setMessage('An error occurred. Please try again.');
            setIsAvailable(false);
            onSuccess(false, ''); // Notify parent component of failure with empty memberId
        }
    });

    const formikRef = useRef(); // Create a ref to store the Formik instance

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        getUserByMemberId({ variables: { memberId: parseInt(values.memberId, 10) } });
        formikRef.current = { values }; // Store the formik values in the ref
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={formikRef} // Pass the ref to the Formik component
        >
            {({ errors, touched, isSubmitting }) => (
                <Form className="mt-5 sm:flex sm:items-center">
                    <div className="w-full sm:max-w-xs">
                        <label htmlFor="memberId" className="sr-only">
                            Member ID
                        </label>
                        <Field
                            type="text"
                            name="memberId"
                            id="memberId"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-blue-800 focus:border-blue-800 sm:text-sm sm:leading-6"
                            placeholder="12345"
                        />
                        {touched.memberId && errors.memberId && <div className="text-red-700 text-sm">{errors.memberId}</div>}
                    </div>
                    <button
                        type="submit"
                        className="mt-3 sm:mt-0 sm:ml-3 btn-primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Checking...' : 'Reserve'}
                    </button>
                    {message && (
                        <div className={`ml-2 text-sm flex items-center ${isAvailable ? 'text-green-500' : 'text-red-700'}`}>
                            {isAvailable ? <CheckCircleIcon className="h-6 w-6 mr-1" aria-hidden="true" /> : <MinusCircleIcon className="h-6 w-6 mr-1" aria-hidden="true" />}
                            {message}
                        </div>
                    )}
                </Form>
            )}
        </Formik>
    )
}
