import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

export default function AccountInformation({ user }) {
  const [membership, setMembership] = useState(null)
  
  useEffect(() => {
    if (user.isActive === true) {
      setMembership('Active')
    } else {
      setMembership('Free')
    }
  }, [user])
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Account Information</h3>
      </div>
      <div className="border-t border-gray-200">
        <dl className="divide-y divide-gray-200">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-md font-semibold text-gray-900">Full name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user.firstName} {user.lastName}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-md font-semibold text-gray-900">Member ID</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user.memberId}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-md font-semibold text-gray-900">Membership Status</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <span className="inline-flex items-center rounded-full bg-green-600 px-1.5 py-0.5 text-xs font-medium text-white mr-3">
                {membership}
              </span>
              {membership === 'Free' && (
              <Link
                to="/plans"
                className="link">
                Upgrade
              </Link>
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-md font-semibold text-gray-900">Email address</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user.email}</dd>
          </div>
        </dl>
      </div>
    </div>
  )
}