import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ResetPassword from "../../components/login/ResetPassword";
import EmailConfirmation from "../../components/login/EmailConfirmation";

export default function UserManagementPage() {
    const [queryParameters] = useSearchParams();
    const mode = queryParameters.get('mode');

    return (
        <div className="w-full h-screen flex flex-col lg:flex-row items-center justify-center">
            <div className="w-full lg:w-1/2 flex flex-col items-center justify-center p-4">
                <div className="w-full max-w-md mt-4 p-4">
                    {mode === 'resetPassword' && <ResetPassword />}
                    {mode === 'resetPassword' && (
                        <p className="mt-4 text-sm text-gray-500">
                            Remember your password?{" "}
                            <Link to="/login" className="link">
                                Sign in
                            </Link>
                        </p>
                    )}
                    {mode === 'verifyEmail' && <EmailConfirmation />}
                </div>
            </div>
            <div className="hidden lg:flex lg:w-1/2 h-full bg-gray-200 items-center justify-center">
                <div
                    className="w-full h-full bg-no-repeat bg-cover bg-center"
                    style={{ backgroundImage: `url('/assets/images/sign-in-cover.jpg')` }}
                />
            </div>
        </div>
    );
}
