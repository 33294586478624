import { gql } from '@apollo/client';

export const GET_USER_BY_EMAIL_QUERY = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      email
      firstName
      lastName
      memberId
      isActive
      discourseProfileUrl
      instagramUsername
      facebookUsername
      createdAt
      usersInterests {
        id
        isPrimary
        matchTypeDivision {
          id
          name
          matchType {
            id
            name
          }
        }
      }
    }
  }
`;


export const GET_USER_BY_MEMBER_ID_QUERY = gql`
  query getUserByMemberId($memberId: Int!) {
    getUserByMemberId(memberId: $memberId) {
      id
      firstName
      lastName
      memberId
      isActive
      discourseProfileUrl
      instagramUsername
      facebookUsername
      createdAt
      usersInterests {
        id
        isPrimary
        matchTypeDivision {
          id
          name
          matchType {
            id
            name
          }
        }
      }
    }
  }
`;


export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        firebaseUid
        dateOfBirth
        guardian {
          id
          firstName
          lastName
          email
        }
      }
      errors
    }
  }
`;

export const CREATE_STRIPE_CHECKOUT_SESSION_MUTATION = gql`
  mutation CreateStripeCheckoutSession($input: CreateStripeCheckoutSessionInput!) {
    createStripeCheckoutSession(input: $input) {
      stripeCheckoutSessionSecret
    }
  }
`;

export const UPDATE_USER_MEMBER_ID_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        memberId
        facebookUsername
        instagramUsername
        updatedAt
      }
      errors
    }
  }
`;

export const GET_SURVEY_BY_NAME_QUERY = gql`
  query getSurveyByName($name: String!) {
    getSurveyByName(name: $name) {
      id
      name
      description
      surveysQuestions {
        id
        question {
          id
          content
          answers {
            id
            content
          }
        }
      }
    }
  }
`;

export const CREATE_SURVEY_RESPONSE_MUTATION = gql`
  mutation CreateUsersSurveysAnswer($input: CreateUsersSurveysAnswerInput!) {
    createUsersSurveysAnswer(input: $input) {
      usersSurveysAnswer {
        id
      }
      errors
    }
  }
`;

export const GET_SURVEY_RESULTS_BY_USER_ID_QUERY = gql`
  query getSurveyResultsByUserId($userId: Int!, $surveyName: String!) {
    getSurveyResultsByUserId(userId: $userId, surveyName: $surveyName) {
      id
      answer {
        id
        content
      }
    }
  }
`;

export const GET_MATCH_TYPES_QUERY = gql`
  query getMatchTypes {
    getMatchTypes {
      id
      name
      matchTypeDivisions {
        id
        name
        shortName
      }
    }
  }
`;

export const UPDATE_USER_INTERESTS_MUTATION = gql`
  mutation UpdateUserInterests($input: UpdateUserInterestsInput!) {
    updateUserInterests(input: $input) {
      userInterests {
        id
        isPrimary
        matchTypeDivision {
          id
          name
          matchType {
            id
            name
          }
        }
      }
      errors
    }
  }
`;