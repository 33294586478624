import { XCircleIcon } from '@heroicons/react/20/solid'

export default function ErrorAlert({ message }) {
    return (
        <div className="rounded-md bg-red-100 p-4 mb-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Attention needed</h3>
                    <div className="mt-2">
                        <p className="text-sm text-red-700">
                            { message }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}