import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/FirebaseAuthContext';
import ForgotPassword from '../../components/login/ForgotPassword';

export default function ForgotPasswordPage() {
    const { userLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (userLoggedIn) {
            navigate('/dashboard');
        }
    }, [userLoggedIn, navigate]);

    return (
        <div className="relative w-full h-screen flex flex-col lg:flex-row items-center justify-center bg-white">
            <div className="absolute top-0 left-0 p-4 w-32 h-auto">
                <Link to="/">
                    <img src='/assets/images/pcsl-logo-letters-only.png' alt="Logo" className="w-full h-auto" />
                </Link>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col items-center justify-center p-4 bg-white">
                <div className="w-full max-w-md mt-4 p-4">
                    <ForgotPassword />
                    <p className="mt-4 text-sm text-gray-500 text-center">
                        Remember your password?{' '}
                        <Link to="/login" className="link">
                            Sign in
                        </Link>
                    </p>
                </div>
            </div>
            <div className="hidden lg:flex lg:w-1/2 h-full bg-gray-200 items-center justify-center">
                <div
                    className="w-full h-full bg-no-repeat bg-cover bg-center"
                    style={{ backgroundImage: `url('/assets/images/sign-in-cover.jpg')` }}
                />
            </div>
        </div>
    );
}
