import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Field } from 'formik'
import { doSignInWithEmailAndPassword } from '../../utils/firebase/auth'
import ErrorAlert from '../common/ErrorAlert';

export default function Login() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    return (
        <div className="mx-auto w-full max-w-sm lg:w-96">
            <h1 className="text-3xl font-semibold">Sign in to PCSL</h1>
            <div className="mt-4">
                {errorMessage && <ErrorAlert message={errorMessage} />}
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    onSubmit={async (values) => {
                        try {
                            await doSignInWithEmailAndPassword(values.email, values.password);
                            navigate('/dashboard');
                        } catch (error) {
                            if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-credential') {
                                setErrorMessage('Invalid email or password');
                            } else {
                                setErrorMessage('An error occurred. Please try again');
                            }
                        }
                    }}
                >
                    {({ isSubmitting, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="email">
                                    Email address
                                </label>
                                <Field
                                    className="formik-input"
                                    type="email"
                                    id="email"
                                    name="email"
                                />
                            </div>
                            <div>
                                <div class="flex items-center justify-between">
                                    <label htmlFor="password">
                                        Password
                                    </label>
                                    <p class="text-sm">
                                        <Link
                                            to="/forgot-password"
                                            class="link"
                                            style={{ fontWeight: 'normal' }}>
                                            Forgot password?
                                        </Link>
                                    </p>
                                </div>
                                <Field
                                    className="formik-input"
                                    type="password"
                                    id="password"
                                    name="password"
                                />
                            </div>
                            <div className="mt-6">
                                <button type="submit" class="w-full text-center btn-primary" disabled={isSubmitting}>
                                    {isSubmitting ? 'Signing in...' : 'Sign in'}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}