import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datepicker-custom.css";
import { useMutation } from '@apollo/client';
import { useAuth } from '../../contexts/FirebaseAuthContext';
import { validationSchema } from './validations/SignupValidation';
import { doCreateUserWithEmailAndPassword, doSendEmailVerification } from '../../utils/firebase/auth';
import { CREATE_USER_MUTATION } from '../../utils/graphql/queries';

export default function Signup({ onStartSignup }) {
    const [showGuardianFields, setShowGuardianFields] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [createUserMutation] = useMutation(CREATE_USER_MUTATION);

    const handleDateOfBirthChange = (date, setFieldValue) => {
        setFieldValue('dateOfBirth', date);
        const today = new Date();
        const age = today.getFullYear() - date.getFullYear();
        const monthDifference = today.getMonth() - date.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < date.getDate())) {
            age--;
        }
        setShowGuardianFields(age < 18);
    };

    return (
        <div className="mx-auto w-full max-w-sm lg:w-96">
            <h1 className="text-3xl text-center font-semibold">Create a PCSL account</h1>
            <div className="mt-4">
                {errorMessage && (
                    <div className="bg-red-50 rounded-md p-4" role="alert">
                        <div className="text-sm text-red-800">
                            <p>
                                Error: {errorMessage}
                            </p>
                        </div>
                    </div>
                )}
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        dateOfBirth: '',
                        agreement: false,
                        guardianFirstName: '',
                        guardianLastName: '',
                        guardianEmail: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            onStartSignup();
                            setSubmitting(true);
                            const authUser = await doCreateUserWithEmailAndPassword(values.email, values.password);
                            doSendEmailVerification();
                            const response = await createUserMutation({
                                variables: {
                                    input: {
                                        firstName: values.firstName,
                                        lastName: values.lastName,
                                        email: values.email,
                                        dateOfBirth: values.dateOfBirth.toISOString().split('T')[0],
                                        firebaseUid: authUser.user.uid,
                                        guardianFirstName: values.guardianFirstName,
                                        guardianLastName: values.guardianLastName,
                                        guardianEmail: values.guardianEmail
                                    }
                                }
                            });
                            if (response.errors !== null) {
                                setErrorMessage('An error occurred. Please try again later.');
                            }
                        } catch (error) {
                            if (error.code === 'auth/email-already-in-use') {
                                setErrorMessage('The email address is already in use by another account.');
                            } else {
                                setErrorMessage('An error occurred. Please try again later.');
                            }
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ values, setFieldValue, errors, touched, isSubmitting, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="firstName">
                                    First Name
                                </label>
                                <Field
                                    className="formik-input"
                                    id="firstName"
                                    name="firstName"
                                />
                                {touched.firstName && errors.firstName && <div className="text-red-700">{errors.firstName}</div>}
                            </div>
                            <div>
                                <label htmlFor="lastName">
                                    Last Name
                                </label>
                                <Field
                                    className="formik-input"
                                    id="lastName"
                                    name="lastName"
                                />
                                {touched.lastName && errors.lastName && <div className="text-red-700">{errors.lastName}</div>}
                            </div>
                            <div>
                                <label htmlFor="email">
                                    Email address
                                </label>
                                <Field
                                    className="formik-input"
                                    type="email"
                                    id="email"
                                    name="email"
                                />
                                {touched.email && errors.email && <div className="text-red-700">{errors.email}</div>}
                            </div>
                            <div>
                                <label htmlFor="password">
                                    Password
                                </label>
                                <Field
                                    className="formik-input"
                                    type="password"
                                    id="password"
                                    name="password"
                                />
                                {touched.password && errors.password && <div className="text-red-700">{errors.password}</div>}
                            </div>
                            <div>
                                <label htmlFor="dateOfBirth">
                                    Date of Birth
                                </label>
                                <Field name="dateOfBirth">
                                    {({ field }) => (
                                        <DatePicker
                                            {...field}
                                            id="dateOfBirth"
                                            name="dateOfBirth"
                                            className="formik-input"
                                            selected={values.dateOfBirth}
                                            onChange={(date) => handleDateOfBirthChange(date, setFieldValue)}
                                            dateFormat="MM/dd/yyyy"
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            placeholderText="Select Date of Birth"
                                        />
                                    )}
                                </Field>
                                {touched.dateOfBirth && errors.dateOfBirth && <div className="text-red-700">{errors.dateOfBirth}</div>}
                            </div>
                            {showGuardianFields && (
                                <>
                                    <div className="mt-4 mb-2 border-t border-gray-300"></div>
                                    <div>
                                        <label htmlFor="guardianFirstName">
                                            Guardian's First Name
                                        </label>
                                        <Field
                                            className="formik-input"
                                            id="guardianFirstName"
                                            name="guardianFirstName"
                                        />
                                        {touched.guardianFirstName && errors.guardianFirstName && <div className="text-red-700">{errors.guardianFirstName}</div>}
                                    </div>
                                    <div>
                                        <label htmlFor="guardianLastName">
                                            Guardian's Last Name
                                        </label>
                                        <Field
                                            className="formik-input"
                                            id="guardianLastName"
                                            name="guardianLastName"
                                        />
                                        {touched.guardianLastName && errors.guardianLastName && <div className="text-red-700">{errors.guardianLastName}</div>}
                                    </div>
                                    <div>
                                        <label htmlFor="guardianEmail">
                                            Guardian's Email
                                        </label>
                                        <Field
                                            className="formik-input"
                                            type="email"
                                            id="guardianEmail"
                                            name="guardianEmail"
                                        />
                                        {touched.guardianEmail && errors.guardianEmail && <div className="text-red-700">{errors.guardianEmail}</div>}
                                    </div>
                                </>
                            )}
                            <div>
                                <label htmlFor="agreement">
                                    <Field
                                        className="formik-checkbox"
                                        type="checkbox"
                                        id="agreement"
                                        name="agreement"
                                    />
                                    I agree to the{' '}
                                    <Link
                                        to="/terms"
                                        className="link"
                                    >
                                        Terms and Conditions of Use
                                    </Link>
                                    {' '}and{' '}
                                    <Link
                                        to="/privacy"
                                        className="link">
                                        Privacy Policy.
                                    </Link>
                                </label>
                                {touched.agreement && errors.agreement && <div className="text-red-700">{errors.agreement}</div>}
                            </div>
                            <div className="mt-6">
                                <button type="submit" className="w-full text-center btn-primary" disabled={isSubmitting}>
                                    {isSubmitting ? 'Submitting...' : 'Create account'}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}