import { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/FirebaseAuthContext';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_BY_EMAIL_QUERY } from '../../utils/graphql/queries';
import CompetitorPersonalitySurvey from '../../components/profile/CompetitorPersonalitySurvey';

const initialSteps = [
    { id: 0, name: 'Intro', href: '#', status: 'current', buttonText: 'Start', imgLink: '/assets/images/survey-intro-cover.jpg' },
    { id: 1, name: 'Personal Fulfillment', href: '#', status: 'upcoming', buttonText: 'Next', imgLink: '/assets/images/survey-personal-fulfillment-cover.jpg' },
    { id: 2, name: 'Love of the Game', href: '#', status: 'upcoming', buttonText: 'Next', imgLink: '/assets/images/survey-love-of-the-game-cover.jpg' },
    { id: 3, name: 'Community & Camaraderie', href: '#', status: 'upcoming', buttonText: 'Next', imgLink: '/assets/images/survey-community-camaraderie-cover.jpg' },
    { id: 4, name: 'Practical Application', href: '#', status: 'upcoming', buttonText: 'Next', imgLink: '/assets/images/survey-practical-application-cover.jpg' },
    { id: 5, name: 'Achieving Greatness', href: '#', status: 'upcoming', buttonText: 'Next', imgLink: '/assets/images/survey-achieving-greatness-cover.jpg' },
    { id: 6, name: 'Results', href: '#', status: 'upcoming', buttonText: 'Done', imgLink: '/assets/images/survey-results-cover.jpg' },
];

export default function CompetitorPersonalitySurveyPage() {
    const [currentStepId, setCurrentStepId] = useState(0);
    const [steps, setSteps] = useState(initialSteps);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const { userLoggedIn, firebaseUser } = useAuth();
    const [getUserByEmail, { loading, error, data: userData }] = useLazyQuery(GET_USER_BY_EMAIL_QUERY);

    useEffect(() => {
        if (userLoggedIn) {
            getUserByEmail({
                variables: {
                    email: firebaseUser.email,
                },
            });
        } else {
            navigate('/login');
        }

        if (!loading && userData) {
            setUser(userData.getUserByEmail);
        }

        if (error) {
            console.error('Error:', error);
        }
    }, [userLoggedIn, firebaseUser, user, getUserByEmail, loading, userData, error, navigate]);

    const handleStepCompletion = (updatedSteps) => {
        setSteps(updatedSteps);
        setCurrentStepId((prevId) => prevId + 1);
    };

    return (
        <div className="flex flex-col h-full">
            <nav aria-label="Progress">
                <ol role="list" className="divide-y divide-gray-300 border border-gray-300 md:flex md:divide-y-0">
                    {steps.map((step, stepIdx) => (
                        <li key={step.name} className={`relative md:flex md:flex-1 ${step.status !== 'current' ? 'hidden md:block' : ''}`}>
                            {step.status === 'complete' ? (
                                <a href={step.href} className="group flex w-full items-center">
                                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-800 group-hover:bg-indigo-500">
                                            <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                                    </span>
                                </a>
                            ) : step.status === 'current' ? (
                                <a href={step.href} className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-blue-800">
                                        <span className="text-indigo-600">{step.id}</span>
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-blue-800">{step.name}</span>
                                </a>
                            ) : (
                                <a href={step.href} className="group flex items-center">
                                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                            <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                                    </span>
                                </a>
                            )}

                            {stepIdx !== steps.length - 1 && (
                                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                                        <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
            <div className="w-full h-full">
                {user && (
                    <CompetitorPersonalitySurvey
                        user={user}
                        steps={steps}
                        currentStepId={currentStepId}
                        onStepCompletion={handleStepCompletion}
                    />
                )}
            </div>
        </div>
    );
}
