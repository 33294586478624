import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MATCH_TYPES_QUERY } from '../../utils/graphql/queries';
import FocusGroup from './FocusGroup';

function Focuses({userId, usersInterests}) {
    const [selectedTab, setSelectedTab] = useState(0);
    const { loading, error, data } = useQuery(GET_MATCH_TYPES_QUERY);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            {/* Mobile dropdown */}
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select Match Type</label>
                <select
                    id="tabs"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={selectedTab}
                    onChange={(e) => setSelectedTab(e.target.value)}
                >
                    {data.getMatchTypes.map((matchType, index) => (
                        <option key={matchType.id} value={index}>
                            {matchType.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Desktop tabs */}
            <div className="hidden sm:block">
                <ul className="flex border-b">
                    {data.getMatchTypes.map((matchType, index) => (
                        <li key={matchType.id} className={`cursor-pointer p-4 ${index === selectedTab ? 'text-blue-600 border-blue-600 border-b-2' : 'text-gray-600'}`} onClick={() => setSelectedTab(index)}>
                            {matchType.name}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Tab content */}
            <div className="m-4">
                {data.getMatchTypes.map((matchType, index) => (
                    index === selectedTab && (
                        <div key={matchType.id}>
                            { !loading && <FocusGroup userId={userId} divisions={matchType.matchTypeDivisions} usersInterests={usersInterests} /> }
                        </div>
                    )
                ))}
            </div>
        </div>
    )
}

export default Focuses;
