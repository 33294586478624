import { CheckCircleIcon } from '@heroicons/react/20/solid'

export default function ErrorAlert({ message }) {
    return (
        <div className="rounded-md bg-green-50 p-4 mb-3">
            <div className="flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <p className="text-sm font-sm text-green-800">{message}</p>
                </div>
            </div>
        </div>
    );
}