import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_STRIPE_CHECKOUT_SESSION_MUTATION, UPDATE_USER_MEMBER_ID_MUTATION } from "../../utils/graphql/queries";
import stripePromise from "../../utils/stripe/stripe";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

export default function CheckoutForm({ memberId, priceId, userEmail }) {
  const [createStripeCheckoutSession, { loading, error, data }] = useMutation(CREATE_STRIPE_CHECKOUT_SESSION_MUTATION);
  const [updateUserMemberId] = useMutation(UPDATE_USER_MEMBER_ID_MUTATION);

  useEffect(() => {
    createStripeCheckoutSession({
      variables: {
        input: {
          stripePricingId: priceId,
          userEmail: userEmail
        }
      }
    });
  }, [createStripeCheckoutSession, priceId, userEmail]);

  const handleOnComplete = () => {
    updateUserMemberId({
      variables: {
        input: {
          memberId: parseInt(memberId, 10),
          userEmail: userEmail
        }
      }
    });
  }

  const options = {
    clientSecret: data?.createStripeCheckoutSession?.stripeCheckoutSessionSecret,
    onComplete: handleOnComplete
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}