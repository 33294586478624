import { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { doApplyActionCode } from '../../utils/firebase/auth';
import ErrorAlert from '../common/ErrorAlert';
import SuccessAlert from '../common/SuccessAlert';

export default function EmailConfirmation() {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [searchParams] = useSearchParams();
    const oobCode = searchParams.get('oobCode');

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await doApplyActionCode(oobCode);
                setSuccessMessage("Email successfully verified. You can now sign in.");
                setErrorMessage('');
            } catch (error) {
                setErrorMessage("An error occurred. Please request a new email confirmation link in your profile.");
                setSuccessMessage('');
            }
        };

        if (oobCode) {
            verifyEmail();
        }
    }, [oobCode]);

    return (
        <div className="mx-auto w-full max-w-sm lg:w-96">
            <h1 className="text-3xl font-semibold">Email Confirmation</h1>
            <div className="mt-4">
                {errorMessage && !successMessage && <ErrorAlert message={errorMessage} />}
                {successMessage && !errorMessage && <SuccessAlert message={successMessage} />}
                {errorMessage && !successMessage &&
                    <div>
                        <p class="mt-4 text-sm text-gray-500">
                            Request a new email confirmation?{' '}
                            <Link
                                to="/account"
                                class="link">
                                Go to Account
                            </Link>
                        </p>
                    </div>
                }
                {
                    successMessage && !errorMessage &&
                    <div>
                        <p class="mt-4 text-sm text-gray-500">
                            Ready to login?{' '}
                            <Link
                                to="/dashboard"
                                class="link">
                                Sign in
                            </Link>
                        </p>
                    </div>
                }
            </div>
        </div>
    )
}