import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/FirebaseAuthContext";
import CheckoutForm from "../../components/plans/CheckoutForm";
import backgroundImage from "../../assets/images/plans-banner.png";
import MemberIdForm from "../../components/plans/MemberIdForm";

const plans = [
    {
        id: 'founding',
        price_id: 'price_1PC7KRKfha74Cuuvc95LlKk0'
    },
]

export default function CheckoutPage() {
    const { plan } = useParams();
    const navigate = useNavigate();
    const selectedPlan = plans.find(p => p.id === plan);
    const { userLoggedIn, firebaseUser } = useAuth();
    const [showCheckoutForm, setShowCheckoutForm] = useState(false);
    const [memberId, setMemberId] = useState('');

    const handleMemberIdFormSuccess = (isAvailable, memberId) => {
        setShowCheckoutForm(isAvailable);
        setMemberId(memberId);
    };

    useEffect(() => {
        if (!userLoggedIn) {
            navigate('/login');
        }
    }, [userLoggedIn, firebaseUser]);

    return (
        <div className="h-screen bg-white">
            <div className="bg-gray-900 bg-no-repeat bg-center" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'top' }}>
                <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
                    <div className="mx-auto max-w-4xl">
                        <p className="mt-8 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                            PCSL Checkout <br className="hidden sm:inline lg:hidden" />
                        </p>
                    </div>
                </div>
                <div className="pb-16">
                    <div className="-mt-24">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="mx-auto max-w-3xl rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10">
                                <h3 className="text-lg font-semibold leading-8 tracking-tight text-blue-800">Secure your Member ID</h3>
                                <div className="mt-2 max-w-xl text-sm text-gray-500">
                                    <p>With the founding membership plan, you can pick your own 5-digit Member ID</p>
                                </div>
                                <MemberIdForm onSuccess={handleMemberIdFormSuccess} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showCheckoutForm && <CheckoutForm memberId={memberId} priceId={selectedPlan.price_id} userEmail={firebaseUser.email} />}        
        </div>
    )
}
