import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_USER_BY_MEMBER_ID_QUERY, GET_SURVEY_RESULTS_BY_USER_ID_QUERY } from "../../utils/graphql/queries";
import Navbar from "../../components/navbar/Navbar";
import ProfileAccountInformation from "../../components/profile/ProfileAccountInformation";
import MatchHistory from "../../components/profile/MatchHistory";
import PrimaryFocuses from "../../components/profile/PrimaryFocuses";
import SecondaryFocuses from "../../components/profile/SecondaryFocuses";
import CompetitorPersonalityChart from "../../components/profile/CompetitorPersonalityChart";

const ProfilePage = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { memberId } = useParams();
    const [user, setUser] = useState(null);
    const [primaryFocuses, setPrimaryFocuses] = useState([]);
    const [secondaryFocuses, setSecondaryFocuses] = useState([]);
    const parsedMemberId = parseInt(memberId);
    const { loading, error, data } = useQuery(GET_USER_BY_MEMBER_ID_QUERY, {
        variables: { memberId: parsedMemberId },
    });
    const [getSurveyAnswers, { loading: surveyAnswersLoading, error: surveyAnswersError, data: surveyAnswersData }] = useLazyQuery(GET_SURVEY_RESULTS_BY_USER_ID_QUERY);
    const [surveyAnswers, setSurveyAnswers] = useState([]);

    useEffect(() => {
        if (data) {
            setUser(data.getUserByMemberId);
            setPrimaryFocuses(data.getUserByMemberId.usersInterests.filter(interest => interest.isPrimary));
            setSecondaryFocuses(data.getUserByMemberId.usersInterests);
            getSurveyAnswers({
                variables: { userId: parseInt(data.getUserByMemberId.id), surveyName: 'Competition Shooter Personality' }
            });
        }
        
        if (surveyAnswersData && !surveyAnswersLoading) {
            const answers = surveyAnswersData.getSurveyResultsByUserId.map(result => parseFloat(result.answer.content));
            setSurveyAnswers(answers);
        }
    }, [data, surveyAnswersData, surveyAnswersLoading]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <Navbar itemCurrent={''} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="py-10 lg:pl-72">
                <div className="px-4 sm:px-6 lg:px-8 space-y-6">
                    <div className="flex flex-col lg:flex-row gap-4">
                        {user && (
                            <div className="w-full lg:w-1/2 space-y-4">
                                <ProfileAccountInformation user={user} />
                                <PrimaryFocuses primaryFocuses={primaryFocuses} />
                                <SecondaryFocuses secondaryFocuses={secondaryFocuses} />
                            </div>
                        )}
                        <div className="w-full lg:w-1/2 space-y-4">
                            <MatchHistory />
                            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-6 sm:px-6">
                                    <div className="items-center">
                                        <h2 className="text-lg font-semibold leading-7 text-gray-900">Competitor Personality Survey</h2>
                                        {user && <CompetitorPersonalityChart userAnswers={surveyAnswers} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;