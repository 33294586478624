const PrimaryFocuses = ({ primaryFocuses }) => {

    return (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-6 sm:px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Primary Focus</h2>
                <div className="flex gap-2 flex-wrap">
                    {primaryFocuses.map(interest => (
                        <span key={interest.id} className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-semibold bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500">
                            {interest.matchTypeDivision.matchType.name} - {interest.matchTypeDivision.name}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PrimaryFocuses;