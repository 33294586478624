import { auth } from "./firebase"
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, updatePassword, sendEmailVerification, applyActionCode, fetchSignInMethodsForEmail, confirmPasswordReset } from "firebase/auth";

export const doCreateUserWithEmailAndPassword = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
}

export const doSignInWithEmailAndPassword = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
}

export const doPasswordReset = email => {
    return sendPasswordResetEmail(auth, email);
}

export const doConfirmPasswordReset = (code, password) => {
    return confirmPasswordReset(auth, code, password);
}

export const doPasswordChange = password => {
    return updatePassword(auth.currentUser, password);
}

export const doSendEmailVerification = () => {
    return sendEmailVerification(auth.currentUser);
}

export const doApplyActionCode = (actionCode) => {
    return applyActionCode(auth, actionCode);
}

export const doFetchSignInMethodsForEmail = email => {
    return fetchSignInMethodsForEmail(auth, email);
}   

export const doSignOut = () => {
    return auth.signOut();
}