import React from 'react';

export default function PrivacyPage() {
    return (
        <div className="p-10">
            <h1 className="text-center">
                Privacy Policy
            </h1>
            <div className="w-3/4 mx-auto">
                <p className="text-justify">
                    <strong>Effective as of: 02/07/2024</strong><br/><br/>
                </p>
                <p className="text-justify">Your privacy is important to us. It is Practical Competition Shooting League's ("<strong>us</strong>" or 
                "<strong>PCSL</strong>") policy to respect your privacy regarding any information we may collect from you across our website, https://www.pcsleague.com,
                and other sites we own and operate (our "<strong>site</strong>" or "<strong>website</strong>").&nbsp;</p><br/>
                <p className="text-justify">Our privacy policy ("<strong>Privacy Policy</strong>") is designed to assist you in understanding how we 
                collect and use the personal information you provide us with to use and to assist you in making informed decisions when using our site.&nbsp;</p><br/>
                <p className="text-justify">As used in this Privacy Policy, "<strong>personal information</strong>" or "<strong>personal data</strong>" 
                means information that relates to an identified individual or to an identifiable individual.&nbsp;</p><br/>
                <ol>
                    <li>
                        <span className="underline">
                            <strong>INFORMATION WE COLLECT.&nbsp;</strong>
                        </span>
                    </li>
                </ol>
                <ol>
                    <li>
                        <strong>Log data.
                        </strong>When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your computer’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details.&nbsp;</li>
                </ol>
                <ol>
                    <li>
                        <strong>Device data.
                        </strong>We may also collect data about the device you are using to access our website. This data may include the device type, operating system, unique device identifiers, device settings, and geo-location data. What we collect can depend on the individual settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available to us.&nbsp;</li>
                </ol>
                <ol>
                    <li>
                        <strong>Personal information</strong>. We may ask for personal information, such as your:&nbsp;</li>
                </ol>
                <ol>
                    <li>Name;&nbsp;</li>
                </ol>
                <ol>
                    <li>Email address;&nbsp;</li>
                </ol>
                <ol>
                    <li>Social media profiles;&nbsp;</li>
                </ol>
                <ol>
                    <li>Date of birth;&nbsp;</li>
                </ol>
                <ol>
                    <li>Phone/mobile number;&nbsp;</li>
                </ol>
                <ol>
                    <li>Home/Mailing address; and/or&nbsp;</li>
                </ol>
                <ol>
                    <li>Payment information.&nbsp;</li>
                </ol>
                <ol>
                    <li>
                        <span className="underline">
                            <strong>YOUR RIGHTS AND CONTROLLING YOUR PERSONAL INFORMATION.&nbsp;</strong>
                        </span>
                    </li>
                </ol>
                <ol>
                    <li>
                        <strong>Choice and consent.
                        </strong>By using our website, purchasing a membership from us, participating in our events or otherwise providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this Privacy Policy. If you are under 16 years of age, you must have, and warrant to us, to the extent permitted by law, that you have your parent or legal guardian’s permission to access and use the website and they (your parents or guardian) have consented to you providing us with your personal information. You do not have to provide personal information to us, however, if you do not, it may affect your use of this website or the events and/or services offered on or through it.&nbsp;</li>
                </ol>
                <ol>
                    <li>
                        <strong>Information from third parties.
                        </strong>If we receive personal information about you from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing personal&nbsp;</li><br/><br/>
                </ol>
            </div>
        </div>
    )
}