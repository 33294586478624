import React from 'react';

export default function TermsPage() {
    return (
        <div className="p-10">
            <h1 className="text-center">
                Terms and Conditions of Use
            </h1>
            <div className="w-3/4 mx-auto">
                <p>
                    <strong>Last Updated: 04/09/2024&nbsp;</strong><br/><br/>
                </p>
                <p>
                    <span className="text-gray-800">Welcome to the official website (our “</span>
                    <span className="text-gray-800">
                        <strong>Site</strong>
                    </span>
                    <span className="text-gray-800">”) of Practical Competition Shooting League (“</span>
                    <span className="text-gray-800">
                        <strong>PCSL</strong>
                    </span>
                    <span className="text-gray-800">”).&nbsp;</span>
                    <br/><br/>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">These terms and conditions (“</span>
                    <span className="text-gray-800">
                        <strong>Terms</strong>
                    </span>
                    <span className="text-gray-800">”) apply to your use of this Site. Please note that these Terms apply&nbsp; regardless of the means of delivery of the Site to you. Any reference to “</span>
                    <span className="text-gray-800">
                        <strong>you</strong>
                    </span>
                    <span className="text-gray-800">” or “</span>
                    <span className="text-gray-800">
                        <strong>your</strong>
                    </span>
                    <span className="text-gray-800">” is a reference to&nbsp; you as a user of the PCSL Site.&nbsp;</span><br/><br/>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>THESE TERMS AND CONDITIONS CONTAIN WARRANTY DISCLAIMERS, OTHER&nbsp; PROVISIONS THAT LIMIT PCSL’S LIABILITY, AND A BINDING ARBITRATION CLAUSE&nbsp; AND CLASS ACTIONS WAIVER THAT IMPACT YOUR RIGHTS ABOUT HOW TO RESOLVE&nbsp; DISPUTES. PLEASE READ THESE TERMS CAREFULLY. USING, ACCESSING AND/OR&nbsp; BROWSING THIS SITE CONSTITUTES ACCEPTANCE OF THESE TERMS. IF YOU DO NOT&nbsp; AGREE TO BE BOUND BY EACH AND EVERY TERM AND CONDITION SET FORTH&nbsp; HEREIN, PLEASE EXIT THIS SITE IMMEDIATELY AND DO NOT USE, ACCESS AND/OR&nbsp; BROWSE IT FURTHER.&nbsp;</strong>
                        <br/><br/>
                    </span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>1.
                        </strong>
                    </span>
                    <span className="text-gray-800 underline">
                        <strong>YOUR OBLIGATIONS REGARDING USE OF THE SITE</strong>
                    </span>
                </p>
                <p>
                    <strong>1.1.
                    </strong>
                    <span className="text-gray-800">
                        <strong>Limitations on Use.
                        </strong>
                    </span>
                    <span className="text-gray-800">You agree to use our Site in a manner consistent with these Terms.
                    </span>Further,&nbsp;
                    <span className="text-gray-800">you may not do any of the following while using our Site:&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>1.1.1.
                        </strong>
                    </span>
                    <span className="text-gray-800">Interfere with or disrupt networks connected to our site or violate the regulations, policies or&nbsp; procedures of such networks;&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>1.1.2.
                        </strong>
                    </span>
                    <span className="text-gray-800">Attempt to gain unauthorized access to our Site, computer systems or networks connected to&nbsp; our Site, through password mining or any other means;&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>1.1.3.
                        </strong>
                    </span>
                    <span className="text-gray-800">Interfere with another user’s use and enjoyment of our Site;&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>1.1.4.
                        </strong>
                    </span>
                    <span className="text-gray-800">Upload any message, information, data, text, software, graphic files or other content&nbsp; (“</span>
                    <span className="text-gray-800">
                        <strong>Content</strong>
                    </span>
                    <span className="text-gray-800">”) that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory,&nbsp; vulgar, obscene, libelous, that may be invasive of another’s privacy, hateful, racially,&nbsp; ethnically or otherwise objectionable;&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>1.1.5.
                        </strong>
                    </span>
                    <span className="text-gray-800">Upload Content that contains a virus, corrupted file, or other harmful component;
                    </span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>1.1.6.
                        </strong>
                    </span>
                    <span className="text-gray-800">Impersonate any person or entity, including, but not limited to, PCSL personnel, or falsely&nbsp; state or otherwise misrepresent any affiliation with any person or entity;&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>1.1.7.
                        </strong>
                    </span>
                    <span className="text-gray-800">Upload any Content that you do not have a right to upload under law or under contractual or&nbsp; fiduciary relationships (including, but not limited to, nondisclosure agreements);&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>1.1.8.
                        </strong>
                    </span>
                    <span className="text-gray-800">Upload any information or grant permissions to view information to any person in a manner&nbsp; that exceeds your contractual or other legal authority;&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>1.1.9.
                        </strong>
                    </span>
                    <span className="text-gray-800">Upload any unsolicited advertising, promotional materials, junk mail, spam, chain letters,&nbsp; pyramid schemes, contests, surveys, or any other form of unauthorized solicitation&nbsp; (commercial or non-commercial);&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>1.1.10.
                        </strong>
                    </span>
                    <span className="text-gray-800">Intentionally or unintentionally violate or encourage or assist another to violate any law or&nbsp; regulation in connection with your use or another’s use of our Site; or&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>1.1.11.
                        </strong>
                    </span>
                    <span className="text-gray-800">Upload any Content that violates or infringes any patent, trademark, trade secret, copyright&nbsp; or any right, of whatever nature, of anyone.&nbsp;</span>
                </p>
                <p>
                    <strong>1.1.12.
                    </strong>
                    <span className="text-gray-800">If you violate any part of this Section 1.1, your permission to use our Site automatically&nbsp; terminates.</span>
                </p>
                <p>
                    <strong>1.2. PSCL Match Levels.&nbsp;</strong>
                </p>
                <p>
                    <strong>1.2.1.
                    </strong>PCSL offers shooting matches categorized into four levels, each with distinct characteristics and levels of involvement from PCSL:&nbsp;</p>
                <p className="text-justify">
                    <strong>1.2.1.0.</strong>
                    Level 0 - Unaffiliated Matches: These matches are events where&nbsp; PCSL is not involved in any capacity. The match organizers are likely using a version (potentially modified) of PCSL’s open source rulebook. The host club likely does not have any type of agreement or involvement with PCSL directly. PCSL assumes no&nbsp; responsibility for the organization or management of these matches regarding any aspect. Level 0 event results are never processed by PCSL’s Rating System.&nbsp;</p>
                <p className="text-justify">
                    <strong>1.2.1.1.</strong>
                    Level 1 - Affiliated Club Matches: These matches are local events where&nbsp; PCSL is not involved in any capacity other than the host club having entered into an agreement with PCSL to run the current published ruleset and guidelines as provided from PCSL. You should note that PCSL assumes no&nbsp; responsibility for the organization or management of these matches. Level 1 events are processed by PCSL’s Rating System provided the host club is in good standing and follows PCSL Affiliate Club Guidelines.&nbsp;</p>
                <p className="text-justify">
                    <strong>1.2.1.2.</strong>
                    Level 2 - Independent Major Events: Level 2 matches consist of smaller&nbsp; championships where PCSL's direct involvement is very minimal, if any. Host clubs for PCSL Level 2 events have entered into an agreement with PCSL to run the current published ruleset and guidelines as provided from PCSL. You should&nbsp; be aware that PCSL's role in these events may vary, and PCSL does not have extensive&nbsp; involvement in the organization or management of these matches. Level 2 events are processed by PCSL’s Rating System provided the host club is in good standing and follows PCSL Affiliate Club Guidelines.&nbsp;</p>
                <p className="text-justify">
                    <strong>1.2.1.3.
                    </strong>Level 3 - Regional Championships: Level 3 matches are regional championships&nbsp; held once per region per year. PCSL takes an active role in organizing and running these&nbsp; championships. You should expect PCSL's involvement in the coordination and&nbsp; management of these events. Level 3 events are always processed by PCSL’s Rating System.</p>
                <p className="text-justify">
                    <strong>1.2.1.4.</strong>
                    Level 4 - National Championships: Level 4 matches represent the national&nbsp; championships of PCSL, held once annually. These matches are entirely produced and&nbsp; managed by PCSL itself or by contract. You can expect PCSL to have full control over the organization,&nbsp; management, and production of these prestigious events. Level 4 events are always processed by PCSL’s Rating System.</p>
                <p>
                    <strong>1.2.2.
                    </strong>By participating in, observing, working at, or vending at any match, regardless of level, you agree to abide by and observe all gun safety laws mandated by state and federal&nbsp; regulations/laws and industry standards.&nbsp;</p>
                <p className="text-justify">
                    <strong>1.3. Requirement to Abide by Rules.
                    </strong>You agree to abide by all PCSL policies stated herein&nbsp; and in the
                    <strong>Privacy Policy</strong>, as updated from time to time, state, local, and federal laws for the&nbsp; duration of your participation in any PCSL event<span className="underline">,</span>
                    while utilizing or accessing the PCSL Site and/or&nbsp; for the duration of your Membership (as defined below) with PCSL, if applicable.&nbsp; You understand that failure to abide these expectations may result in your immediate removal from&nbsp; participating in the event, and appropriate PCSL or legal consequences. You further agree to abide&nbsp; by all policies and procedures stated in the
                    <span className="underline">
                        <strong>PCSL General Rulebook</strong>
                    </span>, as updated from time to&nbsp; time, and any and all rules provided at any PCSL event or otherwise listed on this Site.&nbsp;&nbsp;</p>
                <p className="text-justify">
                    <strong>1.4. Warranties.
                    </strong>You warrant that you are of legal age to purchase or carry a firearm pursuant&nbsp; to applicable State or Federal laws. You further warrant that you will at all times during any PCSL&nbsp; event, maintain safe practices as further detailed in the PCSL General Rulebook and supervise and&nbsp; maintain responsibility for any and all minors, if permitted, that may accompany you to any such&nbsp; event.&nbsp;&nbsp;</p>
                <p>
                    <strong>1.5. Right to Monitor.
                    </strong>PCSL reserves the right to monitor all materials provided on our Site&nbsp; and to remove any which, in the absolute discretion of PCSL, is deemed to be offensive or&nbsp; otherwise in breach of these Terms.
                    <strong>ALL MATERIAL DISPLAYED BY PCSL ON IT’S SITE IS FOR INFORMATIONAL PURPOSES ONLY.&nbsp;</strong>
                </p>
                <p className="text-justify">
                    <strong>1.6. Right to Terminate Account.
                    </strong>PCSL may, at its discretion and in appropriate circumstances, terminate the accounts of users who infringe the rights of copyright holders, or other the rights of other PCSL Members.&nbsp;</p>
                <p>
                    <strong>1.7. Confidential Use of Membership Information.
                    </strong>If you subscribe to a PCSL Membership (“<strong>Membership</strong>”), you may have access to the Members (“<strong>Members</strong>”) only area of the Site and may have access to confidential Membership information, including email addresses, phone numbers, and other contact information. This&nbsp; information may only be used in the course of PCSL business or for the completion of duties assigned to PCSL volunteers. Improper use or dissemination of this information could result in&nbsp; denial of access to the Members area, restriction from participating in PCSL events, termination of PCSL Membership, and/or further legal action by PCSL.&nbsp;</p>
                <p>
                    <strong>1.8. Refund Policy.
                    </strong>PCSL is dedicated to ensuring customer satisfaction.
                    <strong>If you are not satisfied with your Membership, please contact us by email at
                    </strong>
                    <strong>info@pcsleague.com
                    </strong>
                    <strong>within 30 calendar days of subscribing to</strong>
                    <strong>Membership.&nbsp;</strong>
                </p>
                <p>
                    <strong>1.8.1.</strong>
                    To qualify for a refund, Members must not have used their Membership to attend a level 3 or level 4 match (see Section 1.2 above for description of match levels). Additionally, we&nbsp; require that all Members must have a 5- minute phone call with us prior to cancelling their Membership. This allows us to&nbsp; determine eligibility for a refund and to understand how we may have&nbsp; fallen short of your expectations as a Member.&nbsp;</p>
                <p className="text-justify">
                    <strong>1.8.2.
                    </strong>Once eligibility for a refund and Membership cancellation is determined, at our sole&nbsp; discretion, we will terminate your subscription, and no further payments will be necessary. Refunds will be issued to the&nbsp; original payment method used for the Membership purchase. Upon refund issuance, access to the Member area will be immediately revoked.&nbsp;</p>
                <p>
                    <strong>1.8.3.</strong>
                    <span className="underline">
                        <strong>
                            No refunds will be provided after 30 calendar days of subscribing to</strong>
                    </span>
                    <strong></strong>
                    <span className="underline">
                        <strong>any Membership type.</strong>
                    </span>
                </p>
                <p className="text-justify">
                    <strong>1.9. Updates.
                    </strong>After purchasing a Membership from us, you will be registered to receive periodic updates and announcements from PCSL. If you are not interested in receiving any further emails from PCSL, you can email us back requesting no future emails and your information will be promptly removed from PCSL’s email distribution list. It is important to note that un&nbsp;</p>
                <p>subscribing from email delivery does not cancel your access to the Membership area.</p>
                <p>
                    <span className="text-gray-800">
                        <strong>2.
                        </strong>
                    </span>
                    <span className="text-gray-800 underline">
                        <strong>INTELLECTUAL PROPERTY</strong>
                    </span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>2.1. Copyright</strong>
                    </span>
                    <span className="text-gray-800">. All content included on this Site, such as text, graphics, logos, button icons, images,&nbsp; audio clips, digital downloads, data compilations, and software, is the property of PCSL or its&nbsp; content suppliers and is protected by U.S. and international copyright laws. The compilation of all&nbsp; content on this Site is the exclusive property of PCSL, with copyright authorship for this collection&nbsp; by PCSL, and protected by U.S. and international copyright laws.&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">In the event you believe that material or content published on the Site may infringe on your&nbsp; copyright or that of another, please contact
                    </span>
                    <span className="text-gray-800 underline">contact
                    </span>
                    <span className="text-gray-800">PCSL at info@pcsleague.com.&nbsp;&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>2.2. Trademarks</strong>
                    </span>
                    <span className="text-gray-800">. PCSL trademarks and service marks may not be used in connection with any&nbsp; product or service that is not owned by or affiliated with PCSL, in any manner that is likely to&nbsp; cause confusion among customers, or in any manner that disparages or discredits PCSL. All other&nbsp; trademarks not owned by PCSL or its affiliates that appear on this Site are the property of their&nbsp; respective owners, who may or may not be affiliated with, connected to, or sponsored by PCSL.&nbsp;&nbsp;</span>
                </p>
                <p className="text-justify">
                    <strong>2.3. Fair Use Disclaimer.
                    </strong>The law has made it acceptable, under very specific circumstances and for very specific purposes only, for one to use someone else’s copyrighted work without first requiring their consent. The following purposes are commonly considered to be “fair use” under Title 17,&nbsp;</p>
                <p>Section 107 of the United States Code under applicable circumstances and uses, and thus, may not be considered copyright infringement:</p>
                <p>
                    <strong>2.3.1.
                    </strong>Criticism&nbsp;</p>
                <p>
                    <strong>2.3.2.
                    </strong>Comment&nbsp;&nbsp;</p>
                <p>
                    <strong>2.3.3.
                    </strong>News reporting&nbsp;</p>
                <p>
                    <strong>2.3.4.
                    </strong>Teaching Scholarship&nbsp;</p>
                <p>
                    <strong>2.3.5.
                    </strong>Research&nbsp;</p>
                <p>PCSL may use copyrighted material in the manner set forth above. PCSL believes these specific&nbsp; uses constitute "fair use."&nbsp;&nbsp;</p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>2.4. Publicity Release</strong>
                    </span>
                    <span className="text-gray-800">. PCSL may display on this Site, or other official PCSL publication, any and all&nbsp; photographs, audio-visual, or audio recordings of you and/or your likeness or voice captured by&nbsp; PCSL, a PCSL affiliate, volunteer, or local media in connection with your participation in, or in&nbsp; attendance, at a PCSL or PCSL affiliate event in accordance with this Section 2.4 PCSL publicity&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">release policy. All photographs, audio-visual, or audio recordings of you and/or your likeness or&nbsp; voice may be used by PCSL for commercial and non-commercial uses, without restriction. You&nbsp; hereby waive the right to inspect or approve the finished product wherein your image or likeness&nbsp; appears. Additionally, you waive any right to payment, royalties or other compensation arising&nbsp; from or related to the use of your image or likeness.
                    </span>
                    <span className="text-gray-800">
                        <strong>You hereby agree to hold harmless and&nbsp; release and forever discharge PCSL, its parent companies, affiliates, assigns, employees, and&nbsp; contractors from any and all claims, demands, and causes of action which you, your heirs,&nbsp; representatives, or any other persons acting on your behalf have in connection with this use.&nbsp;</strong>
                    </span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>3.
                        </strong>
                    </span>
                    <span className="text-gray-800 underline">
                        <strong>DISCLAIMER OF WARRANTIES, CLAIMS, INDEMNIFICATION AND LIMITATION OF
                        </strong>
                    </span>
                    <span className="text-gray-800 underline">
                        <strong>LIABILITY</strong>
                    </span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>3.1. WARRANTIES.
                        </strong>
                    </span>
                    <span className="text-gray-800">THIS SITE IS PROVIDED BY PCSL ON AN "AS IS" AND "AS&nbsp; AVAILABLE" BASIS. PCSL MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY&nbsp; KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE&nbsp; INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE.&nbsp; YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO&nbsp; THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, PCSL DISCLAIMS ALL&nbsp; WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED&nbsp; WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.&nbsp; PCSL DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM&nbsp; PCSL ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.&nbsp;&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>3.2. LIMITATION OF LIABILITY.
                        </strong>
                    </span>
                    <span className="text-gray-800">PCSL WILL NOT BE LIABLE FOR ANY DAMAGES OF&nbsp; ANY KIND ARISING FROM YOUR PCSL MEMBERSHIP, THE USE OF THIS SITE, OR&nbsp; PARTICIPATION IN ANY PCSL EVENT, INCLUDING, BUT NOT LIMITED TO DIRECT,&nbsp; INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES. CERTAIN&nbsp; STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE&nbsp; EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO&nbsp; YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS&nbsp; MAY NOT APPLY TO YOU, AND SECTION 3.3 SHALL BE APPLICABLE.&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>3.3.
                        </strong>
                    </span>
                    <span className="text-gray-800">
                        <strong>CAP ON LIABILITY:
                        </strong>
                    </span>
                    <span className="text-gray-800">IN NO EVENT WILL THE COLLECTIVE LIABILITY OF PCSL, OR
                    </span>
                    <span className="text-gray-800">ANY OF ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS,</span>
                    <span className="text-gray-800">&nbsp;
                    </span>
                    <span className="text-gray-800">AGENTS AND REPRESENTATIVES, TO YOU FOR ANY AND ALL DAMAGES, INJURIES,
                    </span>
                    <span className="text-gray-800">AND LOSSES ARISING FROM ANY AND ALL CLAIMS AND CAUSES OF ACTION</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">ARISING OUT OF, BASED ON, RESULTING FROM, OR IN ANY WAY RELATED TO THIS
                    </span>
                    <span className="text-gray-800">AGREEMENT EXCEED THE TOTAL AMOUNT PAID BY YOU TO PCSL UNDER THIS</span>
                    <span className="text-gray-800">&nbsp;
                    </span>
                    <span className="text-gray-800">AGREEMENT DURING THE 12-MONTH PERIOD PRECEDING THE DATE OF THE</span>
                    <span className="text-gray-800">&nbsp;
                    </span>
                    <span className="text-gray-800">CLAIM. IN THE CASE OF EXCLUDED CLAIMS, SUCH LIMIT WILL BE EQUAL TO ONE</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">TIMES THE TOTAL AMOUNT PAID BY YOU TO PCSL UNDER THIS AGREEMENT</span>
                    <span className="text-gray-800">&nbsp;
                    </span>
                    <span className="text-gray-800">DURING THE TERM. THE EXISTENCE OF MULTIPLE CLAIMS OR SUITS UNDER OR
                    </span>
                    <span className="text-gray-800">RELATED TO THIS AGREEMENT WILL NOT ENLARGE OR EXTEND THE LIMITATION
                    </span>
                    <span className="text-gray-800">OF MONEY DAMAGES WHICH WILL BE THE CLAIMANT’S SOLE AND EXCLUSIVE</span>
                    <span className="text-gray-800">&nbsp;
                    </span>
                    <span className="text-gray-800">REMEDY.</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>3.4. Indemnification.
                        </strong>
                    </span>
                    <span className="text-gray-800">You agree to defend, indemnify and hold harmless PCSL and its affiliates,&nbsp; sponsors and their officers, directors, employees and agents, from and against any and all claims,&nbsp; including but not limited to third-party claims, liability, actions, demands, costs or expenses&nbsp; (including, but not limited to, attorney’s fees) arising from or relating to your participation in any&nbsp; PCSL event, your use of the Site or any materials provided on the Site, your
                    </span>Membership
                    <span className="text-gray-800">to PCSL, or your breach or violation of these Terms. PCSL reserves the&nbsp; right to defend any such claim, and you agree to provide PCSL with such reasonable cooperation&nbsp; as it may request.&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>3.5. Release of Claims.
                        </strong>
                    </span>You wish to utilize the PSCL Site and/or participate in a PCSL event. You understand that you do so at your own risk. In exchange for being permitted to participate in PCSL&nbsp; events and utilize PCSL’s Site, you hereby release the PCSL and all of its employees, officers, agents, representatives, contractors, attorneys and volunteers from any and all liability for and&nbsp; hereby waive any and all claims for any loss, damage, injuries to person or property, death, claims,&nbsp; demands, lawsuits, expenses and any other liability of any kind, or of to you or any other person,&nbsp; directly or indirectly arising out of or in connection with you participation in or attendance at the&nbsp; event or use of the Site.&nbsp;&nbsp;</p>
                <p>
                    <span className="text-gray-800">4.
                    </span>
                    <span className="text-gray-800 underline">
                        <strong>LINKS TO THIRD PARTY WEBSITES OR SERVICES</strong>
                    </span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">This Site may contain links to other websites (“</span>
                    <span className="text-gray-800">
                        <strong>Linked Sites</strong>
                    </span>
                    <span className="text-gray-800">”). The Linked Sites are not under the&nbsp; control of PCSL and PCSL is not responsible for the contents of any Linked Site, including without&nbsp; limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. PCSL is&nbsp; providing these links to you only as a convenience, and the inclusion of any link does not imply&nbsp; endorsement by PCSL of the Linked Site or any association with its operators. When you select a link&nbsp; to an outside website, you are leaving PCSL’s Site and are subject to the terms of the owners/sponsors&nbsp; of that outside website.&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">5.
                    </span>
                    <span className="text-gray-800 underline">
                        <strong>PRIVACY</strong>
                    </span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">PCSL takes the security of your personal data seriously and your use of this Site is subject to PCSL’s&nbsp; Privacy Policy. Please review our
                    </span>
                    <span className="text-gray-800 underline">
                        <strong>Privacy Policy</strong>
                    </span>
                    <span className="text-gray-800">, which also governs the Site and informs users of our&nbsp; data collection practices.&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">6.
                    </span>
                    <span className="text-gray-800 underline">
                        <strong>LICENSE AND WEBSITE ACCESS</strong>
                    </span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">PCSL grants you a limited license to access and make personal use of this Site and not to download&nbsp; (other than page caching) or modify it, or any portion of it, except with express written consent of&nbsp; PCSL. This license does not include any resale or commercial use of this Site or its contents; any&nbsp; collection and use of any product listings, descriptions, or prices; any derivative use of this site or its&nbsp; contents; any downloading or copying of account information for the benefit of another merchant; or&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">any use of data mining, robots, or similar data gathering and extraction tools. This Site or any portion&nbsp; of this Site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for&nbsp; any commercial purpose without express written consent of PCSL. You may not frame or use framing&nbsp; techniques to enclose any trademark, logo, or other proprietary information (including images, text,&nbsp; page layout, or form) of PCSL and/or our associates without PCSL's express written consent. You may&nbsp; not use any meta tags or any other "hidden text" utilizing PCSL’s name or trademarks without PCSL’s express written consent. Any unauthorized use terminates the permission or license granted by PCSL.&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">You are granted a limited, revocable, and nonexclusive right to create a hyperlink to the home page of&nbsp; PCSL so long as the link does not portray PCSL, its associates, or their products or services in a false,&nbsp; misleading, derogatory, or otherwise offensive matter. You may not use any PCSL logo or other&nbsp; proprietary graphic or trademark as part of the link without express written permission from PCSL.&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>7.
                        </strong>
                    </span>
                    <span className="text-gray-800 underline">
                        <strong>MISCELLANEOUS</strong>
                    </span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>7.1. Governing Law and Jurisdiction</strong>
                    </span>
                    <span className="text-gray-800">. These Terms will be governed by and construed in accordance&nbsp; with the laws of the State of Arizona, and you submit to the non-exclusive jurisdiction of the state&nbsp; and federal courts located in the State of Arizona, Maricopa County for the resolution of any&nbsp; disputes.&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>7.2. Dispute Resolution</strong>
                    </span>
                    <span className="text-gray-800">.&nbsp;&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>7.2.1.
                        </strong>
                    </span>
                    <span className="text-gray-800">PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT&nbsp; YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT&nbsp; AND TO HAVE A JURY HEAR YOUR CLAIMS.&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>7.2.2.
                        </strong>
                    </span>
                    <span className="text-gray-800">To expedite and control the cost of disputes,
                    </span>
                    <span className="text-gray-800">
                        <strong>any dispute or claim relating to your use of&nbsp; any products or services sold or distributed by PCSL or through this Site will be&nbsp; resolved by binding arbitration, rather than in court</strong>
                    </span>
                    <span className="text-gray-800">, except that you may assert claims&nbsp; in small claims court, if your claims qualify. The Federal Arbitration Act and federal&nbsp; arbitration law apply to these Terms.&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>7.2.3.There is no judge or jury in arbitration, and court review of an arbitration award is&nbsp; limited. However, an arbitrator can award on an individual basis the same damages and&nbsp; relief as a court (including injunctive and declaratory relief or statutory damages) and&nbsp; must follow these Terms as a court would.&nbsp;</strong>
                    </span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>7.2.4.</strong>
                    </span>
                    <span className="text-gray-800">To begin an arbitration proceeding, you must send a letter requesting arbitration and&nbsp; describing your claim to our registered agent PCSL, LLC, Attn: Maxwell Gregory&nbsp; Leograndis, 24247 N. 169th Drive, Surprise, AZ 85387. The arbitration will be conducted by&nbsp; the American Arbitration Association (AAA) under its rules, including the AAA’s&nbsp; Supplementary Procedures for Consumer-Related Disputes. The AAA’s rules are available&nbsp; at www.adr.org or by calling 1-800-778-7879. Payment of all filing, administration and&nbsp; arbitrator fees will be governed by the AAA’s rules, and split equally by both parties. The&nbsp; successful party (as determined by the Arbitrator), shall be permitted to seek reimbursement&nbsp; of their attorney’s fees and costs. You may request to have the arbitration conducted by Zoom,&nbsp; based on written submissions, or in person in Arizona State at a mutually agreed upon&nbsp; location.&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">
                        <strong>7.2.5.You agree that any dispute resolution proceedings will be conducted only on an&nbsp; individual basis and not in a class, consolidated or representative action.
                        </strong>
                    </span>
                    <span className="text-gray-800">Any&nbsp;</span>
                </p>
                <p>
                    <span className="text-gray-800">determination made by an Arbitrator shall be binding, and may not be appealed in a court of&nbsp; law.&nbsp;&nbsp;</span>
                </p>
                <p className="text-justify">
                    <strong>7.3. Modifications.
                    </strong>PCSL reserves the right to update these Terms at any time without notice to you.&nbsp; The most current version of the Terms can be reviewed by clicking on the “Terms of Use”&nbsp; hypertext link located at the bottom of the PCSL Site.&nbsp;</p>
                <p>
                    <strong>7.4. Contact.
                    </strong>If you have any questions about the terms and conditions or disclaimers, you may contact us on our Site, or via info@pcsleague.com.</p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>7.5. Revision of Terms</strong>
                    </span>
                    <span className="text-gray-800">. PCSL is permitted to revise these Terms at any time as it sees fit, and by using&nbsp; this Site you are expected to review such Terms on a regular basis to ensure you understand all&nbsp; terms and conditions governing use of the Site.&nbsp;</span>
                </p>
                <p className="text-justify">
                    <span className="text-gray-800">
                        <strong>7.6. Entire Agreement</strong>
                    </span>
                    <span className="text-gray-800">. These Terms, including any legal notices and disclaimers contained on this&nbsp; Site, constitute the entire agreement between PCSL and you in relation to your use of this Site,&nbsp; and supersedes all prior agreements and understandings with respect to the same.</span>
                </p>
            </div>
        </div>
    );
};
