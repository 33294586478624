import { useState, useEffect } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_SURVEY_BY_NAME_QUERY, CREATE_SURVEY_RESPONSE_MUTATION } from '../../utils/graphql/queries';
import CompetitorPersonalityChart from './CompetitorPersonalityChart';
import { Formik, Form, Field } from 'formik';

function CompetitorPersonalitySurvey({ user, steps, currentStepId, onStepCompletion }) {
    const { loading, error, data } = useQuery(GET_SURVEY_BY_NAME_QUERY, { variables: { name: 'Competition Shooter Personality' } });
    const navigate = useNavigate();
    const [buttonText, setButtonText] = useState('');
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [id, setId] = useState(currentStepId);
    const [answers, setAnswers] = useState([]);
    const [providedAnswers, setProvidedAnswers] = useState([]);
    const [createUsersSurveysAnswer] = useMutation(CREATE_SURVEY_RESPONSE_MUTATION);

    useEffect(() => {
        if (!loading && data) {
            setButtonText(steps[id].buttonText);
            if (id === 0) {
                setTitle('Competition Shooting Personality Survey');
                setDescription(data.getSurveyByName.description);
            } else if (id === steps.length - 1) {
                setTitle('Results');
                setDescription('Thank you for completing the Competition Shooting Personality Survey. Here are the results based on your answers. Shooters find satisfaction in competitive shooting for various reasons. Here are five distinct categories that shooters may identify with to different extents. Understanding how much an individual relates to these categories can reveal what aspects are most important to them in competitive shooting. This insight fosters clarity and respect within the community, leading to more productive discussions about refining the details of PCSL or other formats. A rating of 1 means the aspect is completely unrelatable, while a rating of 10 means the aspect is extremely important and you relate to it fully.');
            } else {
                setTitle(steps[id].name);
                setDescription(data.getSurveyByName.surveysQuestions[id - 1].question.content);
                setAnswers(data.getSurveyByName.surveysQuestions[id - 1].question.answers);
            }
        }
    }, [loading, data, steps, id]);

    const submitAnswer = async (selectedAnswerId) => {
        const selectedAnswer = answers.find(answer => answer.id === selectedAnswerId);
        if (!selectedAnswer) return;
        setProvidedAnswers(prevAnswers => [...prevAnswers, selectedAnswer.content]);        

        await createUsersSurveysAnswer({
            variables: {
                input: {
                    userId: user.id,
                    surveysQuestionId: data.getSurveyByName.surveysQuestions[id - 1].id,
                    answerId: selectedAnswerId,
                },
            },
        });

        setId(id + 1);
        const updatedSteps = [...steps];
        updatedSteps[id].status = 'complete';
        if (id + 1 < steps.length) {
            updatedSteps[id + 1].status = 'current';
        }
        onStepCompletion(updatedSteps);
    };

    const handleSubmit = () => {
        if (id === 0) {
            setId(id + 1);
            const updatedSteps = [...steps];
            updatedSteps[id].status = 'complete';
            updatedSteps[id + 1].status = 'current';
            onStepCompletion(updatedSteps);
        } else {
            navigate('/account');
        }
    };

    return (
        <div className="flex flex-col-reverse md:flex-row items-center md:items-start">
            <div className="md:w-1/2 w-full relative">
                <img
                    src={steps[id].imgLink }
                    className="w-full h-auto"
                />
                <div className="absolute top-0 left-0 p-4">
                    <Link to="/dashboard" className="flex items-center font-semibold text-md text-white hover:underline">
                        <ChevronLeftIcon className="h-5 w-5 mr-1" />
                        <span>Dashboard</span>
                    </Link>
                </div>
            </div>
            <div className="md:w-1/2 w-full pt-4 px-6">
                <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
                    {title}
                </h1>
                <p className="text-base sm:text-md md:text-lg lg:text-xl">
                    {description}
                </p>
                {(id === 0) && (
                    <button type="submit" className="text-center btn-primary my-4" onClick={handleSubmit}>
                        {buttonText}
                    </button>
                )}
                {(id === steps.length - 1) && (
                    <div className="max-w-lg">
                        <CompetitorPersonalityChart userAnswers={providedAnswers} />
                        <button type="submit" className="text-center btn-primary my-4" onClick={handleSubmit}>
                            {buttonText}
                        </button>
                    </div>
                )}
                {(id !== 0 && id !== steps.length - 1) && (answers.length > 0) && (
                    <Formik
                        initialValues={{
                            answer: answers[0]?.id || '',
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            submitAnswer(values.answer);
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="my-2">
                                    <label htmlFor="answer" className="text-base sm:text-md md:text-lg lg:text-xl">
                                        Rate between 1-10 depending on how much your interest in competitive shooting is influenced by {title}
                                    </label>
                                    <Field
                                        as="select"
                                        id="answer"
                                        name="answer"
                                        className="mb-2 border text-sm border-gray-300 rounded-md px-4 py-1 focus:outline-none focus:ring-blue-800 focus:border-blue-800;"
                                    >
                                        {answers.map(answer => (
                                            <option key={answer.id} value={answer.id}>{answer.content}</option>
                                        ))}
                                    </Field>
                                </div>
                                <button type="submit" disabled={isSubmitting} className="text-center btn-primary my-4">
                                    {buttonText}
                                </button>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
}

export default CompetitorPersonalitySurvey;
