import { Link } from "react-router-dom";

export default function CheckoutSuccessPage() {
  return (
    <>
      <main className="relative lg:min-h-full h-screen">
        <div className="h-full lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
          <img
            src="https://tailwindui.com/img/ecommerce-images/confirmation-page-06-hero.jpg"
            alt="TODO"
            className="h-full w-full object-cover object-center max-h-full max-w-full"
          />
        </div>

        <div>
          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
            <div className="lg:col-start-2">
              <h1 className="text-sm font-medium text-blue-800">Payment successful</h1>
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Thanks for upgrading</p>
              <p className="mt-2 text-base text-gray-500">
                We appreciate your support, we're currently processing it. So hang tight and we'll send you confirmation
                very soon!
              </p>

              <div className="mt-16 border-t border-gray-200 py-6 text-right">
                <Link
                  to="/login"
                  className="link" aria-hidden="true">
                  Continue to Dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
